<template>
    <section class="product_details">
        <div class="container">
            <div class="dashboard">
                <div class="container-fluid">
                    <div class="row">
                        <div class="profile-top-bar">
                            <div class="dashboard-breadcrumb">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="#">Ordered Information</a></li>
                                    </ol>
                                </nav>
                                <div class="text-right" style="color: white">
                                    {{ me.customer_name }}
                                </div>

                                <!--<div class="dropdown">
                                    <button
                                            class="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                    >
                                        {{ me.customer_name }}
                                    </button>
                                    <div
                                            class="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                    >
                                        <a class="dropdown-item" href="#"> Profile</a>
                                        <a class="dropdown-item" href="#"
                                        ><i class="fa fa-sign-out pull-right"></i> Log Out</a
                                        >
                                    </div>
                                </div>-->
                            </div>
                        </div>
                    </div>

                    <div class="row row-flex">
                        <Navbar></Navbar>
                            <div class="col-md-9 " >
                                <!--<div class="dashboard-right">
                                    <div class="dashboard-quotation">
                                        <div class="container-fluid" id="dashboard_right">
                                            <div class="row">
                                                &lt;!&ndash;<pre>{{customerSummery.orders}}</pre>&ndash;&gt;
                                                <div v-if="loading" class=""><img src="../MyDashboard/loading.gif" alt=""></div>
                                                <div v-if="!loading" class="col">
                                                    <div class="business-address-list">
                                                        <table
                                                                id="datatable-buttons-click"
                                                                class="table table-striped table-bordered euro-table"
                                                        >
                                                            <thead>
                                                            <tr>
                                                                <th>SL</th>
                                                                <th>Order Date</th>
                                                                <th>Order Id</th>
                                                                <th>Quantity</th>
                                                                <th>Total</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr  role="row" class="odd"  v-for="(order, index ) in customerSummery.orders" :key="order.id">
                                                                <td class="sorting_1">
                                                                    {{index+1}}
                                                                </td>
                                                                <td>
                                                                    {{order.order_date}}
                                                                </td>
                                                                <td>
                                                                    {{ order.orderID }}
                                                                </td>
                                                                <td>
                                                                    {{ order.order_items[0].quantity }}
                                                                </td>
                                                                <td>
                                                                    {{ order.total_amount }}
                                                                </td>
                                                                <td>
                                                                    {{ order.order_status===0?'cancel':
                                                                    order.order_status===1?'Pending':
                                                                    order.order_status===2?'confirm':
                                                                    order.order_status===3?'Hold':
                                                                    order.order_status===4?'shipped':
                                                                    order.order_status===5?'delivered':
                                                                    order.order_status===6?'partial delivered':''
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    <button :disabled="order.order_status !== 1" class="btn btn-danger" type="button" @click="cancelOrder(order.id)">Cancel</button>
                                                                    &nbsp;
                                                                    <button class="btn btn-success" type="button" @click="viewDetails(order.id)">View</button>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                                <template>
                                    <div>
                                        <v-table class="table euro-table"
                                                 :data="customerSummery.orders"
                                                 :currentPage.sync="currentPage"
                                                 :pageSize="10"
                                                 @totalPagesChanged="totalPages = $event"
                                        >
                                            <thead slot="head">
                                            <!--<th>SL</th>-->
                                            <th>Order Date</th>
                                            <th>Order Id</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                            <th>Status</th>
                                            <th style="text-align: right">Action</th>
                                            </thead>
                                            <tbody slot="body" slot-scope="{displayData}">
                                            <tr v-for="row in displayData" :key="row.guid">
                                                <td>{{ row.order_date }}</td>
                                                <td>{{ row.orderID }}</td>
                                                <td>{{ row.order_items[0].quantity }}</td>
                                                <td>{{ row.total_amount }}</td>
                                                <td :class="row.order_status===0?'cancel':
                                                        row.order_status===1?'pending':
                                                        row.order_status===2?'confirm':
                                                        row.order_status===3?'hold':
                                                        row.order_status===4?'shipped':
                                                        row.order_status===5?'delivered':
                                                        row.order_status===6?'partial':''">
                                                    <span>
                                                        {{ row.order_status===0?'cancel':
                                                    row.order_status===1?'Pending':
                                                    row.order_status===2?'confirm':
                                                    row.order_status===3?'Hold':
                                                    row.order_status===4?'shipped':
                                                    row.order_status===5?'delivered':
                                                    row.order_status===6?'partial delivered':''
                                                    }}
                                                    </span>

                                                </td>
                                                <td style="text-align: right">
                                                    <button :disabled="row.order_status !== 1" class="btn btn-danger" type="button" @click="cancelOrder(row.id)">Cancel</button>
                                                    &nbsp;
                                                    <button class="btn btn-success" type="button" @click="viewDetails(row.id)">View</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </v-table>

                                        <smart-pagination
                                                :currentPage.sync="currentPage"
                                                :totalPages="totalPages"
                                        />
                                    </div>
                                </template>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import {mapGetters} from "vuex";
    import Navbar from "@/components/frontend/MyDashboard/Navbar.vue";

    export default {
        name: 'Pagination',
        data: () => ({
            currentPage: 1,
            totalPages: 0
        }),
        computed: {
            ...mapGetters(["me", "customerSummery","loading"]),
        },
        components: {
            Navbar,
        },
        methods :{
            viewDetails(orderId){
                this.$router.push('/order_details/'+ orderId).catch(() => {})
            },
            cancelOrder(orderid){
                console.log("===>>", orderid);
                this.$store.dispatch('cancelOrder', orderid);
                console.log("rrrrr")
                this.$store.dispatch('getcustomerSummery');
                console.log("rrrrrhh")
                this.$store.dispatch('getcustomerSummery');
                this.$toasted.success('Successfully Canceled.');
            }

        }
    };
</script>
<style lang="scss">
    @import "@/assets/frontend/style/dashboard.scss";
    @import "@/assets/frontend/style/details.scss";
    @import "@/assets/frontend/style/bs_leftnavi.scss";
    @import "@/assets/frontend/style/customize.scss";
    table.euro-table {
        width: 100%;
    }
    table.table.euro-table tr td {
        padding: 6px 10px;
        border: 1px solid #ddd;
    }
    ul.pagination li {
        display: inline-block;
    }

    ul.pagination li a {
        padding: 0px 15px;
    }

    li.page-item.active a {
        background: #007bfe;
        border-radius: 100%;
        height: 30px;
        width: 30px;
        text-align: center;
        padding: 3px;
        display: block;
        color: #fff;
    }
    nav.smart-pagination {
        text-align: right;
        margin: 15px 0px;
    }
    td.cancel span {
        background: #e95959;
        border-radius: 10px;
        padding: 0px 10px;
        color: #fff;
    }
    td.pending span {
        background: #e9a00c;
        border-radius: 10px;
        padding: 0px 10px;
        color: #000;
    }
</style>
